<template>
    <v-container>
        <!-- <v-row no-gutters class="flex-nowrap"></v-row> -->
        <v-row>
            <v-col cols="12" class="dashboard pa-0 ma-0">
                <img
                    class="dashboard__logo"
                    :src="`${path}`"
                    alt="entelli logo"
                />
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import store from '../../store/index'
import { mapGetters, mapActions } from 'vuex'
export default {
    data: () => ({
        path: `${process.env.VUE_APP_SERVER}/api/v2/getAppLogo`,
    }),

    computed: {
        ...mapActions(['fetchSettings']),
        ...mapGetters(['getSettingsDetails']),
    },

    beforeMount() {
        this.$forceUpdate()
        store.dispatch('fetchSettings', {
            type: 'add',
        })
    },
}
</script>
<style lang="scss" scoped>
.dashboard__logo {
    width: 250px;
    border-radius: 0% 0% 0% 0% / 0% 0% 0% 0%;
    color: white;
    box-shadow: 2px 2px 0px rgba(0, 0, 0, 0.1);
    transition: all 0.5s ease;
}
.dashboard__logo:hover {
    border-radius: 1% 0%;
    box-shadow: 3px 3px rgba(0, 0, 0, 0.11);
}
</style>
